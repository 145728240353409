/**
 * 配列に関するユーティリティ群
 */
export const ArrayUtils = {
    /**
     * ふたつの配列の要素が等しいかどうか判定します。
     *
     * @param xs 比較対象 1
     * @param ys 比較対象 2
     * @returns ふたつの配列が等しければ `true`、それ以外は `false`
     */
    equals(xs: readonly unknown[], ys: readonly unknown[]): boolean {
        if (xs.length !== ys.length) {
            return false;
        }

        for (let i = 0; i < xs.length; ++i) {
            if (xs[i] !== ys[i]) {
                return false;
            }
        }

        return true;
    },
};
